<template>
  <div>
    <div v-if="$getDebug()">
      mw{{ modelValue }}<br />
      ml{{ modelLocal }}<br />
      tfdv{{ textFieldDynamicValue }}<br />
      sel{{ selected }} sel{{ selected[0] }}
    </div>
    <v-text-field
      prepend-icon="mdi-account-search"
      :label="label"
      :rules="rules"
      v-model="textFieldDynamicValue"
      :loading="loading"
      @click="openDialog"
      @focus="focus = true"
      @blur="focus = false"
    />
    <v-dialog v-model="dialog">
      <v-card>
        <v-container>
          <v-row class="row search spacer-md">
            <v-col cols="12" sm="6" md="4" lg="2">
              <search-contact
                ref="search-contact-picker"
                :rules="rules"
                name="screcursion1"
                @search="search"
                store-name="SearchContactPickerStore"
                :init-query="{}"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <searchContactAttributeAndRelationInner
                :initFromUrl="false"
                name="recursion1"
                :rules="rules"
                store-name="SearchContactAttributesRelationsPickerStore"
                :show-picker="false"
                ref="search-contact-attributes-picker"
                @search="search"
              />
            </v-col>
          </v-row>
          <v-btn color="primary" @click="search"><span v-html="$t('search')" /></v-btn>
          <v-btn color="secondary" class="margin-left" @click="reset"><span v-html="$t('reset')" /></v-btn>
          <v-data-table-server
            v-model="selected"
            select-strategy="single"
            item-value="contactId"
            show-select
            :headers="fields"
            :items="items"
            :fields="fields"
            :loading="loading"
            :items-length="totalElements"
            @update:itemsPerPage="
              (size: number) => {
                localQuery.size = size
              }
            "
            @update:page="
              (page: number) => {
                localQuery.page = page - 1
              }
            "
            @update:sort-by="
              (sort: any) => {
                localQuery.sort = toJavaSort(sort)
              }
            "
            :sort-by="toDTSort(localQuery.sort)"
            :page="localQuery.page + 1"
            :items-per-page="localQuery.size"
            :items-per-page-options="$itemsPerPageOptions"
            density="compact"
          >
            <template v-slot:[`item.id`]="{ item }">
              <router-link
                :to="{
                  name: 'contacts_edit',
                  params: { id: item.parentId, tab: 'overview' }
                }"
                class="tableaction link"
              >
                {{ item.relationName1 }}
              </router-link>
            </template>
            <template v-slot:[`item.relationName1`]="{ item }">
              <router-link
                :to="{
                  name: 'contacts_edit',
                  params: { id: item.parentId, tab: 'overview' }
                }"
                class="tableaction link"
              >
                {{ item.relationName1 }}
              </router-link>
            </template>
          </v-data-table-server>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { apiURL } from '../../main'
import { Term } from '@/services/term'
import { showError } from '@/services/axios'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'

import { useSearchContactAttributesRelationsPickerStore } from '@/store/SearchContactAttributesRelationsPickerStore'

import { DTSHeader } from '@/services/BackendService'

import searchContactAttributeAndRelationInner from '@/components/searchCards/searchContactAttributeAndRelationInner.vue'

import { useSearchContactPickerStore } from '@/store/SearchContactPickerStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'contactParentIdPicker',
  props: {
    modelValue: { type: Number, required: false },
    label: String,
    rules: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  emit: ['update:modelValue', 'input'],
  data() {
    return {
      dialog: false,
      loading: false,
      focus: false,
      totalElements: 0,
      contact: { name1: '', name2: '' },
      items: [],
      localQuery: {
        sort: 'id,asc',
        page: 0,
        size: 3
      },
      fields: <DTSHeader[]>[
        // edit
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // contact_id
        {
          title: this.$t('contact_search_independent_contact_id'),
          key: 'contactId',
          sortable: false,
          align: 'left'
        },
        // contact_no
        {
          title: this.$t('contact_search_independent_contact_no'),
          key: 'contactNo',
          sortable: false,
          align: 'left'
        },
        // Name / Firma
        {
          title: this.$t('contact_search_independent_name1'),
          key: 'name1',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('contact_search_independent_name2'),
          key: 'name2',
          sortable: true
        }
      ]
    }
  },
  computed: {
    SearchContactPickerStore() {
      return useSearchContactPickerStore()
    },
    term(): any {
      return [
        ...Term.buildTermItems(useSearchContactPickerStore(), useSearchContactPickerStore().items),
        ...Term.buildTermItems(
          useSearchContactAttributesRelationsPickerStore(),
          useSearchContactAttributesRelationsPickerStore().items.filter((item) => item.key != 'contactParentId')
        )
      ]
    },
    query(): any {
      return {
        ...this.localQuery,
        term: Term.stringify(this.term)
      }
    },
    modelLocal: {
      get: function () {
        return this.modelValue
      },
      set: function (value) {
        let itemX = this.items.filter((x) => x.contactId == value)[0]
        if (typeof value == 'string') {
          this.$emit('input', value)
          this.$emit('update:modelValue', value)
          this.contact.name1 = ''
          this.contact.name2 = ''
        } else {
          this.contact.name1 = itemX.name1 || ''
          this.contact.name2 = itemX.name2 || ''
          this.$emit('input', value)
          this.$emit('update:modelValue', value)
        }
      }
    },
    valueAndName() {
      if (this.modelValue === null) return ''
      if (this.modelValue === undefined) return ''
      return !this.contact.name1 ? this.modelValue : `${this.modelValue} : ${this.contact.name1} ${this.contact.name2}`
    },
    textFieldDynamicValue: {
      get: function () {
        console.log(this.focus, this.modelLocal, this.valueAndName)
        return this.focus ? this.modelLocal : this.valueAndName
      },
      set: function (value) {
        if (typeof value == 'string') {
          this.modelLocal = value
          this.contact.name1 = ''
          this.contact.name2 = ''
        } else if (typeof value === 'object' && value !== null) {
          this.modelLocal = value.contactId
          this.contact.name1 = value.name1 || ''
          this.contact.name2 = value.name2 || ''
        } else {
          this.modelLocal = ''
        }
      }
    },
    selected: {
      get: function () {
        return [] //
      },
      set: function (value) {
        this.modelLocal = value[0]
        this.dialog = false
      }
    }
  },
  components: {
    searchContact,
    searchContactAttributeAndRelationInner
  },
  methods: {
    openDialog() {
      this.dialog = true
      //this.load()
    },
    search() {
      this.load()
    },
    async load() {
      console.log('load')
      this.loading = true

      this.items = []
      const response = await this.axios.get(apiURL + '/contacts/search', {
        params: { ...this.query, ...{ contactSearchType: 'ALL' } }
      })
      const items = response.data.content
      this.items = Array.isArray(items) ? items : []
      this.totalElements = this.checkPage(response.data.totalElements)
      try {
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-contact-picker'].reset()
      this.$refs['search-contact-attributes-picker'].reset()
    }
  },
  watch: {
    modelValue(to) {
      if (to === null) {
        this.contact.name1 = ''
        this.contact.name2 = ''
      }
    },
    localQuery: {
      handler() {
        this.load()
      },
      deep: true
    }
  }
})
</script>
